import React from "react"
import { Link } from "gatsby"
import { GloblaStyle } from '../theme'
import { Helmet } from 'react-helmet'

const NotFoundPage = () => {
  return (
    <main style={{ padding: '12vmin'}}>
      <GloblaStyle />
      <Helmet>
        <title>404 - Page not found</title>
        <link rel="preconnect" href="https://fonts.gstatic.com" /> 
        <link href="https://fonts.googleapis.com/css2?family=Rubik:wght@300..900&display=swap" rel="stylesheet" />
      </Helmet>
      <h1>Page not found</h1>
      <p>
        Sorry{" "}
        <span role="img" aria-label="Pensive emoji">
          😔
        </span>{" "}
        we couldn’t find what you were looking for.
      </p>
      <p>
        <Link to="/">Go home</Link>
      </p>
    </main>
  )
}

export default NotFoundPage
